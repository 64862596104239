class PinchZoom {
    constructor(element) {
        this.element = element;
        this.container = element.parentElement;
        this.scale = 1;
        this.initialDistance = null;
        this.startX = 0;
        this.startY = 0;
        this.currentX = 0;
        this.currentY = 0;

        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);

        this.element.addEventListener('touchstart', this.handleTouchStart);
        this.element.addEventListener('touchmove', this.handleTouchMove);
        this.element.addEventListener('touchend', this.handleTouchEnd);
    }

    getDistance(touches) {
        const [touch1, touch2] = touches;
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    handleTouchStart(event) {
        if (event.touches.length === 2) {
            this.initialDistance = this.getDistance(event.touches);
        } else if (event.touches.length === 1) {
            this.startX = event.touches[0].clientX - this.currentX;
            this.startY = event.touches[0].clientY - this.currentY;
        }
    }

    handleTouchMove(event) {
        if (event.touches.length === 2) {
            const currentDistance = this.getDistance(event.touches);
            const delta = currentDistance / this.initialDistance;
            this.scale *= delta;
            this.scale = Math.min(Math.max(this.scale, 1), 2.5); // Limit the scale between 1 and 2.5
            this.initialDistance = currentDistance;
            this.element.style.transform = `scale(${this.scale}) translate(${this.currentX}px, ${this.currentY}px)`;
        } else if (event.touches.length === 1) {
            // this.currentX = event.touches[0].clientX - this.startX;
            // this.currentY = event.touches[0].clientY - this.startY;
            // // Boundary checks
            // const rect = this.element.getBoundingClientRect();
            // const containerRect = this.container.getBoundingClientRect();
            // if (rect.left > containerRect.left) {
            //     this.currentX -= rect.left - containerRect.left;
            // }
            // if (rect.top > containerRect.top) {
            //     this.currentY -= rect.top - containerRect.top;
            // }
            // if (rect.right < containerRect.right) {
            //     this.currentX += containerRect.right - rect.right;
            // }
            // if (rect.bottom < containerRect.bottom) {
            //     this.currentY += containerRect.bottom - rect.bottom;
            // }
            // this.element.style.transform = `scale(${this.scale}) translate(${this.currentX}px, ${this.currentY}px)`;
        }
    }

    handleTouchEnd(event) {
        if (event.touches.length < 2) {
            this.initialDistance = null;
        }
    }
}

export default PinchZoom;
